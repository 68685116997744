<template>
  <div class="weather-graph-container">
    <div class="sunset-sunrise">
      <div>
        <p>
          <strong>Sunrise</strong>
        </p>
        <p class="grey-text">{{ getLocalTime(getWeeklyWeather[daySelected].sunrise, "sunrise") }}</p>
      </div>
      <div>
        <p>
          <strong>Sunset</strong>
        </p>
        <p class="grey-text">{{ getLocalTime(getWeeklyWeather[daySelected].sunset, "sunset") }}</p>
      </div>
    </div>
    <canvas id="suntime-graph" />
  </div>
</template>

<script>
import Chart from 'chart.js'
import weatherChartData from '../../assets/data/suntime-chart-config.js'

export default {
  name: 'SuntimeGraph',
  data () {
    return {
      weatherChartData: weatherChartData,
      sunriseTime: 0,
      sunsetTime: 0,
      newChart: undefined
    }
  },
  computed: {
    selectedCityWeather () {
      return this.$store.state.selectedCityWeather ? this.$store.state.selectedCityWeather : {}
    },
    getWeeklyWeather () {
      return this.selectedCityWeather.daily ? this.selectedCityWeather.daily.filter((val, i) => i < 7) : {}
    },
    daySelected () {
      return this.$store.state.daySelected
    }
  },
  watch: {
    sunriseTime () {
      this.createChart('suntime-graph', this.weatherChartData)
    },
    sunsetTime () {
      this.createChart('suntime-graph', this.weatherChartData)
    }
  },
  methods: {
    createChart (chartId, chartData) {
      const ctx = document.getElementById(chartId).getContext('2d')
      // ctx.height = 100;
      const gradient = ctx.createLinearGradient(0, 0, 0, 450)

      gradient.addColorStop(0, 'rgb(245, 176, 65, 1)')
      gradient.addColorStop(0.25, 'rgb(241, 196, 15, 0.5)')
      chartData.data.datasets[1].backgroundColor = gradient

      chartData.data.datasets[0].data = [
        { x: 4, y: -2 },
        { x: this.sunriseTime, y: 0 }
      ]
      chartData.data.datasets[1].data = [
        { x: this.sunriseTime, y: 0 },
        { x: (this.sunsetTime + this.sunriseTime) / 2, y: 10 },
        { x: this.sunsetTime, y: 0 }
      ]
      chartData.data.datasets[2].data = [
        { x: this.sunsetTime, y: 0 },
        { x: 20, y: -2 }
      ]
      this.newChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      })
    },
    getLocalTime (epoch, type) {
      const date = new Date(epoch * 1000).toLocaleTimeString('en-US').split(/:| /)
      if (type === 'sunrise') {
        this.sunriseTime = parseFloat((parseInt(date[0]) + parseInt(date[1]) / 60).toPrecision(3))
      }
      if (type === 'sunset') {
        this.sunsetTime = parseFloat((12 + parseInt(date[0]) + parseInt(date[1]) / 60).toPrecision(3))
      }
      return `${date[0]}:${date[1]} ${date[3].toLowerCase()}`
    }
  }
}
</script>

<style scoped>
.weather-graph-container {
  position: relative;
  height: 150px;
}
.sunset-sunrise {
  display: flex;
  justify-content: space-between;
}
</style>
