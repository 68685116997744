<template>
  <div>
    <div class="search-container">
      <font-awesome-icon class="search-icon icon-location" icon="map-marker-alt" />
      <input
        type="text"
        class="search-bar"
        placeholder="Search"
        :value="searchText"
        @input="(evt) => (searchText = evt.target.value)"
      />
      <font-awesome-icon class="search-icon icon-search" icon="search" />
      <div v-if="isTyping && searchText.length > 1" class="search-list">
        <div
          v-for="(singleCity, i) in matchingCities"
          :key="singleCity.lat + singleCity.lng"
          class="search-list-content"
          @click="citySelected(singleCity)"
        >
          <p>
            <!-- eslint-disable-next-line -->
            <span v-html="matchingSubString(singleCity.city)" />
            ,
            <span class="grey-text">
              {{
                singleCity.admin_name
              }}
            </span>
          </p>
          <div class="search-list-weather-container">
            <div class="search-list-weather">
              <p>
                <strong>
                  {{
                    matchingCityWeather[i]
                      ? Math.round(
                        matchingCityWeather[i].data
                          .main.temp
                      )
                      : "[]"
                  }}&#176;C
                </strong>
              </p>
              <p class="small-text">
                {{
                  matchingCityWeather[i]
                    ? matchingCityWeather[i].data.weather[0]
                      .main
                    : ""
                }}
              </p>
            </div>
            <img
              v-if="
                (matchingCityWeather[i]
                  ? matchingCityWeather[i].data.weather[0]
                    .main
                  : ''
                ).toLowerCase() === 'clouds'
              "
              src="../assets/icons/cloud.svg"
            />
            <img
              v-else-if="
                (matchingCityWeather[i]
                  ? matchingCityWeather[i].data.weather[0]
                    .main
                  : ''
                ).toLowerCase() === 'rain'
              "
              src="../assets/icons/rain.svg"
            />
            <img v-else src="../assets/icons/sun.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indianCities from '../assets/data/cities.json'
import axios from 'axios'

export default {
  name: 'SearchBox',
  props: {
    setDefault: Boolean
  },
  data () {
    return {
      isTyping: false,
      searchText: '',
      matchingCities: '',
      matchingCityWeather: [],
      currentlocation: {}
    }
  },
  watch: {
    searchText () {
      if (this.searchText.length > 1) {
        this.isTyping = true
        this.matchingCities = indianCities.filter(singleCity => singleCity.city.toLowerCase().includes(this.searchText.toLowerCase()))
        axios.all(this.matchingCities.map(city => axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${city.lat}&lon=${city.lng}&units=metric&appid=${process.env.VUE_APP_OW_API_ID}`))).then(
          axios.spread((...res) => {
            this.matchingCityWeather = res
          })
        )
      }
    },
    setDefault () {
      this.searchText = 'New Delhi, Delhi'
    }
  },
  methods: {
    matchingSubString (cityName) {
      const matchingStringIndex = cityName.toLowerCase().indexOf(this.searchText.toLowerCase())

      return cityName.slice(0, matchingStringIndex) +
        `<strong>${cityName.slice(matchingStringIndex, matchingStringIndex +
          this.searchText.length)}</strong>` + cityName.slice(matchingStringIndex + this.searchText.length)
    },
    async citySelected (city) {
      this.$emit('loadingStart')
      try {
        this.searchText = `${city.city}, ${city.admin_name}`
        await this.$store.dispatch('getCityWeathers', { lat: city.lat, lon: city.lng }).then(() => {
          this.$emit('loadingEnd')
        })
        this.isTyping = false
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>
.search-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 1rem;
}
.search-bar {
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  border: none;
  box-shadow: 0 0 10px 0px #aaaa;
  transition: box-shadow 0.5s ease-out;
  border-radius: 10px;
  width: 100%;
}
.search-bar:focus {
  outline: none;
  box-shadow: 0 0 2px 2px #5dade2;
}
.search-icon {
  position: absolute;
}
.icon-location {
  left: 1rem;
}
.icon-search {
  right: 1rem;
}
.search-list {
  top: 115%;
  z-index: 5;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px #aaaa;
  animation: searchListContainerAnimation 0.5s;
}
.search-list-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  animation: searchListAnimation 0.5s;
}
.search-list-content:last-of-type {
  border: none;
}
.search-list-content:hover {
  cursor: pointer;
}
@keyframes searchListAnimation {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes searchListContainerAnimation {
  0% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.search-list-weather-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-list-weather-container img {
  width: 3rem;
  margin-left: 0.5rem;
}
</style>
