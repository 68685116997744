
export const weatherChartData = {
  type: 'line',
  data: {
    datasets: [
      {
        pointBorderColor: '#5dade2',
        pointBackgroundColor: '#ffffff',
        pointBorderWidth: 3,
        pointRadius: 5,
        borderColor: '#5dade2',
        borderWidth: 3
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (tooltipItems) {
          return 'Temp: ' + tooltipItems.yLabel + '°C'
        },
        labelTextColor: function () {
          return '#222'
        }
      },
      titleFontColor: '#222',
      backgroundColor: '#ffffff',
      borderColor: '#ff0000',
      displayColors: false,
      xPadding: 15,
      yPadding: 10
    },
    responsive: true,
    maintainAspectRatio: false,
    lineTension: 1,
    scales: {
      yAxes: [{
        ticks: {
          suggestedMin: 0,
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    },
    animation: {
      duration: 1000,
      easing: 'linear'
    }
  }
}

export default weatherChartData
