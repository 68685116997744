<template>
  <div class="card">
    <div class="current-temp">
      <h1>{{ Math.round(getWeeklyWeather[daySelected].temp.max) }}&#176;C</h1>
      <img
        v-if="
          getWeeklyWeather[daySelected].weather[0].main.toLowerCase() ===
          'clouds'
        "
        src="../assets/icons/cloud.svg"
      />
      <img
        v-else-if="
          getWeeklyWeather[daySelected].weather[0].main.toLowerCase() === 'rain'
        "
        src="../assets/icons/rain.svg"
      />
      <img v-else src="../assets/icons/sun.svg" />
    </div>
    <div class="hourly-temp-graph">
      <temperature-graph />
    </div>
    <div class="weather-parameters">
      <div class="weather-parameter">
        <h3>Pressure</h3>
        <p>{{ getWeeklyWeather[daySelected].pressure }} hpa</p>
      </div>
      <div class="weather-parameter">
        <h3>Humidity</h3>
        <p>{{ getWeeklyWeather[daySelected].humidity }}%</p>
      </div>
    </div>
    <div class="suntime-graph">
      <suntime-graph />
    </div>
  </div>
</template>

<script>
import TemperatureGraph from './Graphs/TemperatureGraph.vue'
import SuntimeGraph from './Graphs/SuntimeGraph.vue'

export default {
  name: 'WeatherReport',
  components: {
    TemperatureGraph,
    SuntimeGraph
  },
  computed: {
    selectedCityWeather () {
      return this.$store.state.selectedCityWeather ? this.$store.state.selectedCityWeather : {}
    },
    getWeeklyWeather () {
      return this.selectedCityWeather.daily ? this.selectedCityWeather.daily.filter((val, i) => i < 7) : {}
    },
    daySelected () {
      return this.$store.state.daySelected
    }
  }
}
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 0 0 10px 0 #aaaa;
  min-height: 500px;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  border-radius: 10px;
  background-color: #fff;
}
.current-temp {
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
}
.current-temp h1 {
  font-size: 3rem;
  font-weight: bold;
}
.current-temp img {
  width: 3em;
  margin-left: 1rem;
}
.hourly-temp-graph,
.suntime-graph {
  overflow-x: auto;
  min-height: 200px;
  margin-bottom: 2rem;
}
.weather-parameters {
  display: flex;
  white-space: nowrap;
}
.weather-parameter {
  position: relative;
  flex-grow: 1;
  text-align: left;
  background-color: #5dade225;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
}
.weather-parameters div:first-child {
  margin-right: 2rem;
}
</style>
