<template>
  <div class="weather-graph-container">
    <canvas id="planet-chart" />
  </div>
</template>

<script>
import Chart from 'chart.js'
import weatherChartData from '../../assets/data/weather-chart-config.js'

export default {
  name: 'TemperatureGraph',
  data () {
    return {
      weatherChartData: weatherChartData,
      tempChart: null
    }
  },
  computed: {
    selectedCityWeather () {
      return this.$store.state.selectedCityWeather ? this.$store.state.selectedCityWeather : {}
    },
    getWeeklyWeather () {
      return this.selectedCityWeather.daily ? this.selectedCityWeather.daily.filter((val, i) => i < 7) : {}
    },
    getHourlyWeather () {
      return this.selectedCityWeather.hourly ? this.selectedCityWeather.hourly : {}
    },
    daySelected () {
      return this.$store.state.daySelected
    }
  },
  watch: {
    daySelected () {
      this.generateData()
    }
  },
  mounted () {
    this.generateData()
  },
  methods: {
    createChart (chartId, chartData) {
      const ctx = document.getElementById(chartId).getContext('2d')
      const gradient = ctx.createLinearGradient(0, 0, 0, 450)

      gradient.addColorStop(0, 'rgb(93,173,226, 0.5)')
      gradient.addColorStop(0.5, 'rgb(93,173,226, 0)')
      chartData.data.datasets[0].backgroundColor = gradient

      this.tempChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      })
    },
    generateData () {
      if (this.tempChart) {
        this.tempChart.destroy()
      }
      this.weatherChartData.data.datasets[0].data = []
      this.weatherChartData.data.labels = []
      const selectedDay = new Date(this.getWeeklyWeather[this.daySelected].dt * 1000).toDateString('en-US')
      let index = 0
      while (index < this.getHourlyWeather.length) {
        if (new Date(this.getHourlyWeather[index].dt * 1000).toDateString('en-US') === selectedDay) {
          const currTime = new Date(this.getHourlyWeather[index].dt * 1000).toLocaleTimeString('en-US').split(/:| /)
          this.weatherChartData.data.datasets[0].data.push(this.getHourlyWeather[index].temp)
          this.weatherChartData.data.labels.push(`${currTime[0]}${currTime[3].toLowerCase()}`)
        }
        index++
      }
      if (!this.weatherChartData.data.datasets[0].data.length) {
        this.weatherChartData.data.datasets[0].data = [25, 24, 26, 27, 22, 23, 26, 29, 25, 22, 19, 15]
        this.weatherChartData.data.labels = ['0am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am']
      }
      this.createChart('planet-chart', this.weatherChartData)
    }
  }
}
</script>

<style scoped>
.weather-graph-container {
  position: relative;
  width: 1500px;
  height: 30vh;
}
</style>
