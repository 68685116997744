<template>
    <div class="days-container">
        <div
            v-for="(day, i) in getWeeklyWeather"
            :key="day.dt"
            class="single-day-container"
        >
            <input
                type="radio"
                class="day-forcast-selector"
                name="fatfit"
                :checked="daySelected === i"
                @click="changeDaySelected(i)"
            />
            <div class="day-forcast-content">
                <p>
                    <strong>{{ dayMap(new Date(day.dt * 1000)) }}</strong>
                </p>
                <p>
                    <strong
                        >{{ Math.round(day.temp.max) }}&#176;
                        <span class="grey-text"
                            >{{ Math.round(day.temp.min) }}&#176;<span /></span
                    ></strong>
                </p>
                <p v-if="day.weather[0].main.toLowerCase() === 'clouds'">
                    <img src="../assets/icons/cloud.svg" />
                </p>
                <p v-else-if="day.weather[0].main.toLowerCase() === 'rain'">
                    <img src="../assets/icons/rain.svg" />
                </p>
                <p v-else>
                    <img src="../assets/icons/sun.svg" />
                </p>
                <p class="grey-text">
                    <strong>{{ day.weather[0].main }}</strong>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'DayMarker',
  data () {
    return {
      weeklyWeather: {}
    }
  },
  computed: {
    selectedCityWeather () {
      return this.$store.state.selectedCityWeather ? this.$store.state.selectedCityWeather : {}
    },
    getWeeklyWeather () {
      return this.selectedCityWeather.daily ? this.selectedCityWeather.daily.filter((val, i) => i < 7) : {}
    },
    daySelected () {
      return this.$store.state.daySelected
    }
  },
  methods: {
    changeDaySelected (value) {
      this.$store.dispatch('updatedDaySelected', { value })
    },
    dayMap (day) {
      switch (day.getUTCDay()) {
        case 0:
          return 'Sun'
        case 1:
          return 'Mon'
        case 2:
          return 'Tue'
        case 3:
          return 'Wed'
        case 4:
          return 'Thu'
        case 5:
          return 'fri'
        case 6:
          return 'Sat'
      }
    }
  }
}
</script>

<style scoped>
.days-container {
    display: flex;
    font-size: 0.9rem;
    overflow-x: auto;
    margin: 1rem;
    white-space: nowrap;
}
.single-day-container {
    position: relative;
}
.day-forcast-selector {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}
.day-forcast-selector:checked ~ .day-forcast-content {
    box-shadow: 0 0 2px 2px #5dade2;
}
.day-forcast-content {
    margin: 0.25rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.5s ease;
    align-items: center;
}
.day-forcast-content p:first-child {
    margin-bottom: 0.25rem;
}
.day-forcast-content p img {
    margin: 0.5rem 0;
    width: 50%;
}
</style>
