
export const weatherChartData = {
  type: 'line',
  data: {
    datasets: [
      {
        pointRadius: 0,
        borderColor: '#aaa',
        borderWidth: 1
      },
      {
        pointRadius: 0,
        borderColor: 'rgb(249, 231, 159)',
        borderWidth: 1
      },
      {
        pointRadius: 0,
        borderColor: '#aaa',
        borderWidth: 1
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    tooltips: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    lineTension: 1,
    scales: {
      yAxes: [{
        ticks: {
          suggestedMax: 11,
          display: false
        },
        gridLines: {
          display: false
        }
      }],
      xAxes: [{
        ticks: {
          suggestedMax: 20,
          stepSize: 4,
          callback: function (value) {
            const time = value / 12 > 1 ? 'pm' : 'am'
            return value / 12 === 1 ? '12pm' : value % 12 + time
          }
        },
        gridLines: {
          display: false
        },
        type: 'linear',
        position: 'bottom'
      }]
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuad'
    }
  }
}

export default weatherChartData
