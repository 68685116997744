var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"search-container"},[_c('font-awesome-icon',{staticClass:"search-icon icon-location",attrs:{"icon":"map-marker-alt"}}),_c('input',{staticClass:"search-bar",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":_vm.searchText},on:{"input":function (evt) { return (_vm.searchText = evt.target.value); }}}),_c('font-awesome-icon',{staticClass:"search-icon icon-search",attrs:{"icon":"search"}}),(_vm.isTyping && _vm.searchText.length > 1)?_c('div',{staticClass:"search-list"},_vm._l((_vm.matchingCities),function(singleCity,i){return _c('div',{key:singleCity.lat + singleCity.lng,staticClass:"search-list-content",on:{"click":function($event){return _vm.citySelected(singleCity)}}},[_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.matchingSubString(singleCity.city))}}),_vm._v(" , "),_c('span',{staticClass:"grey-text"},[_vm._v(" "+_vm._s(singleCity.admin_name)+" ")])]),_c('div',{staticClass:"search-list-weather-container"},[_c('div',{staticClass:"search-list-weather"},[_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.matchingCityWeather[i] ? Math.round( _vm.matchingCityWeather[i].data .main.temp ) : "[]")+"°C ")])]),_c('p',{staticClass:"small-text"},[_vm._v(" "+_vm._s(_vm.matchingCityWeather[i] ? _vm.matchingCityWeather[i].data.weather[0] .main : "")+" ")])]),(
              (_vm.matchingCityWeather[i]
                ? _vm.matchingCityWeather[i].data.weather[0]
                  .main
                : ''
              ).toLowerCase() === 'clouds'
            )?_c('img',{attrs:{"src":require("../assets/icons/cloud.svg")}}):(
              (_vm.matchingCityWeather[i]
                ? _vm.matchingCityWeather[i].data.weather[0]
                  .main
                : ''
              ).toLowerCase() === 'rain'
            )?_c('img',{attrs:{"src":require("../assets/icons/rain.svg")}}):_c('img',{attrs:{"src":require("../assets/icons/sun.svg")}})])])}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }