<template>
  <div id="app">
    <search-box
      :set-default="setDefault"
      @loadingStart="isLoading = true"
      @loadingEnd="isLoading = false"
    />
    <img v-if="isLoading" src="spinner.svg" alt="loading" />
    <day-marker v-if="!isLoading" />
    <weather-report v-if="!isLoading" />
  </div>
</template>

<script>
import axios from 'axios'
import SearchBox from './components/SearchBox.vue'
import DayMarker from './components/DayMarker.vue'
import WeatherReport from './components/WeatherReport.vue'

export default {
  name: 'App',
  components: {
    SearchBox,
    DayMarker,
    WeatherReport
  },
  data () {
    return {
      isLoading: true,
      setDefault: false
    }
  },
  async mounted () {
    this.isLoading = true
    await axios.get('http://ip-api.com/json/').then(response => {
      this.$store.dispatch('getCityWeathers', { lat: response.data.lat, lon: response.data.lon }).then(() => {
        this.isLoading = false
      })
    }).catch(() => {
      this.$store.dispatch('getCityWeathers', { lat: '28.7000', lon: '77.2000' }).then(() => {
        this.setDefault = true
        this.isLoading = false
      })
    })
  }
}
</script>

<style>
#app {
  font-family: Arial, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
