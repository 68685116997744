import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'

// load Vuex
Vue.use(Vuex)

const state = {
  selectedCityWeather: {},
  daySelected: 0
}

const getters = {}

const actions = {
  async getCityWeathers ({ commit }, { lat, lon }) {
    commit('setdaySelected', 0)
    await axios.get(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=minutely&units=metric&appid=${process.env.VUE_APP_OW_API_ID}`)
      .then(response => {
        commit('setCityWeather', response.data)
      })
  },
  updatedDaySelected ({ commit }, { value }) {
    commit('setdaySelected', value)
  },
  updateCityWeather ({ commit }, { response }) {
    commit('setCityWeather', response)
  }
}

const mutations = {
  setCityWeather (state, cityWeather) {
    state.selectedCityWeather = cityWeather
  },
  setdaySelected (state, value) {
    state.daySelected = value
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
